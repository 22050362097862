<template>
  <div class="lang_pair">
    <a-select
      v-model="sourceCode"
      placeholder="Source"
      showSearch
      allowClear
      :filter-option="langFilterOption"
      class="select-w"
    >
      <a-select-option
        v-for="(item, index) in langPair"
        :key="index"
        :value="item.code"
        :disabled="sourceDisable(item)"
      >
        {{ item.name }}
      </a-select-option>
    </a-select>
    <div class="rignt-arrow">
      <img src="@/assets/image/translationRecord-rignt-arrow.png" alt="" />
    </div>
    <a-select
      v-model="targetCode"
      placeholder="Target"
      showSearch
      allowClear
      :filter-option="langFilterOption"
      class="select-w"
    >
      <a-select-option
        v-for="(item, index) in langPair"
        :key="index"
        :value="item.code"
        :disabled="targetDisable(item)"
      >
        {{ item.name }}
      </a-select-option>
    </a-select>
  </div>
</template>

<script>
export default {
  data() {
    return {
      sourceCode: undefined,
      targetCode: undefined,
    }
  },
  props: {
    sourceCodeList: {
      type: Array,
      default: () => [],
    },
    targetCodeList: {
      type: Array,
      default: () => [],
    },
    langPairList: {
      type: Array,
      default: () => [],
    },
    id: {
      type: Number,
      default: 0,
    },
    isClear: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    sourceCode(newVal, oldVal) {
      const { sourceCode, targetCode, id } = this
      this.$g.noEmpty(oldVal) && this.$emit('del', 'source', oldVal)
      this.$g.noEmpty(newVal) && this.$emit('add', 'source', newVal)
      if (this.$g.noEmpty(sourceCode) && this.$g.noEmpty(targetCode)) {
        this.$emit('updateLangPairList', { id, sourceCode, targetCode })
      }
    },
    targetCode(newVal, oldVal) {
      const { sourceCode, targetCode, id } = this
      this.$g.noEmpty(oldVal) && this.$emit('del', 'target', oldVal)
      this.$g.noEmpty(newVal) && this.$emit('add', 'target', newVal)
      if (this.$g.noEmpty(sourceCode) && this.$g.noEmpty(targetCode)) {
        this.$emit('updateLangPairList', { id, sourceCode, targetCode })
      }
    },
    isClear(v) {
      if (v) {
        this.sourceCode = undefined
        this.targetCode = undefined
      }
    },
  },
  computed: {
    langPair() {
      return this.$store.state.app.langList
    },
  },
  methods: {
    sourceDisable(item) {
      const { targetCode, targetCodeList, langPairList } = this
      return (
        item.code === targetCode ||
        (targetCodeList.includes(targetCode) &&
          item.code === langPairList.find((obj) => obj.targetCode === targetCode)?.sourceCode)
      )
    },

    targetDisable(item) {
      const { sourceCode, sourceCodeList, langPairList } = this
      return (
        item.code === sourceCode ||
        (sourceCodeList.includes(sourceCode) &&
          item.code === langPairList.find((obj) => obj.sourceCode === sourceCode)?.targetCode)
      )
    },
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // onLangSourceChange(code) {
    //   const { sourceCode, targetCode } = this
    //   console.log(`code1`, code)
    //   this.$emit('sourceLangChange', { sourceCode, targetCode })
    // },
    // onLangTargetChange(code) {
    //   console.log(`code2`, code)
    //   const { sourceCode, targetCode } = this
    //   this.$emit('targetLangChange', { sourceCode, targetCode })
    // },
  },
}
</script>

<style lang="less" scoped>
.lang_pair {
  display: flex;
  line-height: 32px;
  .rignt-arrow {
    width: 29px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 400;
    font-family: PingFangSC-Regular, PingFang SC;
    font-size: 18px;
    color: #46546c;
    line-height: 25px;
    padding: 0 10px;
  }
  .select-w {
    width: 169px;
  }
  // ::v-deep {
  //   .ant-select-selection--single {
  //     width: 100%;
  //   }
  // }
}
</style>
