<template>
  <div class="AddNewSupplierOne" v-show="show">
    <NeoTitle :text="$t('customerConfig.base_info')" class="_mb-20" />

    <a-form-model
      ref="form"
      :model="form"
      :label-col="formSet.labelCol"
      :wrapper-col="formSet.wrapperCol"
      :rules="rules"
    >
      <a-row>
        <!-- 供应商税号 -->
        <a-col :span="formSet.span">
          <a-form-model-item
            :label="$t('customerConfig.supplier_tax')"
            :labelAlign="formSet.labelAlign"
            prop="supplierTax"
          >
            <a-input v-model="form.supplierTax" placeholder="" />
          </a-form-model-item>
        </a-col>
        <!-- 供应商实体名 -->
        <a-col :span="formSet.span">
          <a-form-model-item
            :label="$t('customerConfig.supplier_name')"
            :labelAlign="formSet.labelAlign"
            prop="supplierName"
          >
            <a-input v-model="form.supplierName" placeholder="" />
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <!-- 供应商地址 -->
        <a-col :span="formSet.span">
          <a-form-model-item
            :label="$t('customerConfig.supplier_address')"
            :labelAlign="formSet.labelAlign"
            prop="supplierAddress"
          >
            <a-input v-model="form.supplierAddress" placeholder="" />
          </a-form-model-item>
        </a-col>
        <!-- 供应商管理员 -->
        <a-col :span="formSet.span">
          <div class="supplier-admin">
            <a-form-model-item
              :label="$t('customerConfig.supplier_admin')"
              :labelAlign="formSet.labelAlign"
              prop="supplierAdminName"
              class="supplier-admin-name"
            >
              <a-input v-model="form.supplierAdminName" :placeholder="$t('managerInfo.name')" />
              <!-- _w-pct-43 -->
            </a-form-model-item>
            <a-form-model-item :labelAlign="formSet.labelAlign" prop="supplierAdminEmail" class="supplier-admin-email">
              <a-input v-model="form.supplierAdminEmail" :placeholder="$t('email')" />
            </a-form-model-item>
          </div>
        </a-col>
      </a-row>
      <a-row>
        <!-- 推荐产品线 -->
        <a-col :span="formSet.span">
          <a-form-model-item :label="$t('customerConfig.contact_productLine')" :labelAlign="formSet.labelAlign">
            <a-select v-model="form.contactProductLine" mode="multiple" placeholder="">
              <a-select-option v-for="item in productLines" :key="item.id" :value="item.id">
                {{ item.name }}
              </a-select-option>
            </a-select>
            <a-tooltip placement="right">
              <template slot="title">
                <span>{{ $t('customerConfig.tip_select_recommend') }}</span>
              </template>
              <img class="tip-icon" src="@/assets/image/tip-icon.png" alt="" />
            </a-tooltip>
          </a-form-model-item>
        </a-col>
      </a-row>
      <a-row>
        <!-- 推荐语言对 -->
        <a-col :span="formSet.span">
          <a-form-model-item :label="$t('customerConfig.contact_lang_pair')" :labelAlign="formSet.labelAlign">
            <div class="_rel">
              <template v-for="($item, index) of langPairData">
                <div :key="index" class="_rel _top-5" :class="{ '_mt-15': index !== 0 }">
                  <div class="lang_pair">
                    <a-form-item
                      style="margin-bottom: 0px"
                      :validate-status="getValidateStatus($item, $item.sourceCode)"
                      :help="getValidateTip($item, $item.sourceCode)"
                    >
                      <a-select
                        v-model="$item.sourceCode"
                        placeholder="Source"
                        showSearch
                        allowClear
                        :filter-option="langFilterOption"
                        class="select-w"
                      >
                        <a-select-option
                          v-for="(item, index) in langPair"
                          :key="index"
                          :value="item.code"
                          :disabled="sourceDisable($item, item)"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                    <div class="rignt-arrow">
                      <img src="@/assets/image/translationRecord-rignt-arrow.png" alt="" />
                    </div>
                    <a-form-item
                      style="margin-bottom: 0px"
                      :validate-status="getValidateStatus($item, $item.targetCode)"
                      :help="getValidateTip($item, $item.targetCode)"
                    >
                      <a-select
                        v-model="$item.targetCode"
                        placeholder="Target"
                        showSearch
                        allowClear
                        :filter-option="langFilterOption"
                        class="select-w"
                      >
                        <a-select-option
                          v-for="(item, index) in langPair"
                          :key="index"
                          :value="item.code"
                          :disabled="targetDisable($item, item)"
                        >
                          {{ item.name }}
                        </a-select-option>
                      </a-select>
                    </a-form-item>
                  </div>
                  <!-- 第一行固定显示 -->
                  <template v-if="index === 0">
                    <a-tooltip placement="right">
                      <template slot="title">
                        <span>{{ $t('customerConfig.tip_select_recommend2') }}</span>
                      </template>
                      <img class="tip-icon1" src="@/assets/image/tip-icon.png" alt="" />
                    </a-tooltip>
                    <!-- 添加 -->
                    <img
                      class="_abs _point _top-10"
                      style="right: -62px"
                      @click="addLangPairSel"
                      src="@/assets/image/plus-icon.png"
                      alt=""
                    />
                  </template>

                  <!-- 删除 -->
                  <img
                    class="_abs _point _top-10 _w-20"
                    style="right: -31px"
                    v-if="index > 0 && index < langPairData.length"
                    @click="delLangPairSel($item.id)"
                    src="@/assets/image/cut-icon.png"
                    alt=""
                  />
                </div>
              </template>
              <!-- </NeoScroll> -->
              <!-- 
              <img class="tip-icon2 _point" @click="addLangPairSel" src="@/assets/image/plus-icon.png" alt="" />
              <a-tooltip placement="right">
                <template slot="title">
                  <span>{{ $t('customerConfig.tip_select_recommend2') }}</span>
                </template>
                <img class="tip-icon1" src="@/assets/image/tip-icon.png" alt="" />
              </a-tooltip> -->
            </div>
          </a-form-model-item>
        </a-col>
      </a-row>
    </a-form-model>

    <!-- 生产对接 -->
    <footer class="produce-dock">
      <NeoTitle :text="$t('companyInfo.Pline_dock')" class="_mb-20" />
      <a-radio-group v-model="form.produceSystem" @change="onRadioChange" class="_ml-22">
        <a-radio :value="2" class="c-2">{{ $t('customerConfig.dock_jingyi_internal') }}</a-radio>
        <a-radio :value="0" class="c-2">{{ $t('customerConfig.no_dock_produce_sys') }}</a-radio>
      </a-radio-group>
      <div class="_mt-30">
        <a-button class="_w-100" @click="next" type="primary" :loading="btnloading">{{ $t('next') }}</a-button>
      </div>
    </footer>
  </div>
</template>

<script>
import LangPair from './LangPair'
import debounce from 'lodash/debounce'

const { validate } = window.$g

export default {
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LangPair,
  },
  data() {
    return {
      formSet: {
        span: 12,
        labelAlign: 'right',
        labelCol: { span: 4 },
        wrapperCol: { span: 11 },
      },
      form: {
        supplierTax: '',
        supplierName: '',
        supplierAddress: '',
        supplierAdminName: '',
        supplierAdminEmail: '',
        supplierAdmin: '',
        contactProductLine: undefined,
        contactLangPair: '',
        produceSystem: 2,
      },
      productLines: [],
      rules: {
        supplierTax: [validate.required, validate.only_num_letter, validate.size_20],
        supplierName: [validate.required, validate.regular_str_less_size_100],
        supplierAddress: [validate.required, validate.regular_str_less_size_100],
        supplierAdminName: [validate.required],
        supplierAdminEmail: [validate.required, validate.email],
      },
      radioVal: 2,
      langPair: [],
      addedLangPairAmount: 2,
      langPairData: [{ id: 0, sourceCode: undefined, targetCode: undefined }],
      langCodeMap: new Map(),
      langPairList: [],
      isClearLangPair: false,
      isAddedOne: false,
      btnloading: false,
      baseInfo: [],
      isStartValidate: false,
      langPairCheckArr: [],
    }
  },
  created() {
    this.langPair = this._langPair
    this.getCusProductLineList()
  },
  watch: {
    // addedLangPairAmount(l) {
    //   if (l >= 3) {
    //     this.$nextTick(() => {
    //       const el = document.getElementById('selScroll')
    //       el.scrollTop = el.scrollHeight
    //       // console.log(`el`, el)
    //       // console.log('当前滚动条位置:' + el.scrollTop)
    //       // console.log('当前可滚动区域容器的高度:' + el.scrollHeight)
    //     })
    //   }
    // },
    supplierAdmin() {
      const { supplierAdminName, supplierAdminEmail } = this.form
      const noEmpty = this.$g.noEmpty
      if (noEmpty(supplierAdminName) && noEmpty(supplierAdminEmail)) {
        this.form.supplierAdmin = supplierAdminName + supplierAdminEmail
      }
    },
  },
  computed: {
    _langPair() {
      return this.$store.state.app.langList
    },
    supplierAdmin() {
      return this.form.supplierAdminName + this.form.supplierAdminEmail
    },
    sourceCodeList() {
      return Array.from(
        new Set([...this.langPairData.filter((item) => item.sourceCode).map((item) => item.sourceCode)])
      )
    },
    targetCodeList() {
      return Array.from(
        new Set([...this.langPairData.filter((item) => item.targetCode).map((item) => item.targetCode)])
      )
    },
    discountRate() {
      return this.$store.state.app.discountRate
    },
    cusEntityId() {
      return this.$store.state.app.userInfo.agencyId
    },
  },
  methods: {
    getValidateStatus({ sourceCode, targetCode }, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return 'error'
      } else {
        return ''
      }
    },
    getValidateTip({ sourceCode, targetCode }, code) {
      const error = !!sourceCode !== !!targetCode && !code
      if (error) {
        return this.$t('validate.required')
      } else {
        return ''
      }
    },
    sourceDisable($item, item) {
      const { langPairData, targetCodeList } = this
      const { targetCode } = $item

      const targetItem = langPairData.find((obj) => obj.targetCode === targetCode && this.$g.noEmpty(obj.sourceCode))
      const factor1 = item.code === targetCode
      const factor2_1 = targetCodeList.includes(targetCode)
      const factor2_2 = item.code === targetItem?.sourceCode
      const factor2 = factor2_1 && factor2_2
      return factor1 || factor2
    },

    targetDisable($item, item) {
      const { sourceCodeList, langPairData } = this
      const { sourceCode } = $item
      const targetItem = langPairData.find((obj) => obj.sourceCode === sourceCode && this.$g.noEmpty(obj.targetCode))
      const factor1 = item.code === sourceCode
      const factor2_1 = sourceCodeList.includes(sourceCode)
      const factor2_2 = item.code === targetItem?.targetCode
      const factor2 = factor2_1 && factor2_2
      return factor1 || factor2
    },
    langFilterOption(input, option) {
      return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
    },
    // 客户产品线表-列表
    async getCusProductLineList() {
      const agencyId = this.$store.state.app.userInfo.agencyId
      try {
        const data = await this.$http('cusProductLineList', { entityId: agencyId })
        this.productLines = data
      } catch (err) {
        this.$httpNotify(err)
      }
    },
    langPairNoSingle() {
      return this.langPairData.every((item) => !!item.sourceCode === !!item.targetCode)
    },
    // 下一步
    next() {
      if (!this.isAddedOne) {
        this.$refs.form.validate((valid) => {
          if (valid && this.langPairNoSingle()) {
            this.addBasicInfo()
          } else {
            return false
          }
        })
      } else {
        this.$refs.form.validate((valid) => {
          if (valid && this.langPairNoSingle()) {
            this.updateBasicInfo()
          } else {
            return false
          }
        })
      }
    },

    async checkSupByTaxIdNumber() {
      // eslint-disable-next-line no-unused-vars
      let res = null
      try {
        this.btnloading = true
        const params = {
          cusEntityId: this.cusEntityId,
          taxIdNumber: this.form.supplierTax,
        }
        const data = await this.$http('checkSupByTaxIdNumber', params)
        this.btnloading = false
        res = data
        if (data) {
          this.$message.error(this.$t('customerConfig.tax_has'))
        }
      } catch (err) {
        res = 1
        this.btnloading = false
        this.$httpNotify(err)
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return res
      }
    },
    async checkBySupName() {
      let res = null
      try {
        this.btnloading = true
        const params = {
          cusEntityId: this.cusEntityId,
          name: this.form.supplierName,
        }
        const data = await this.$http('checkBySupName', params)
        this.btnloading = false
        res = data
        if (data) {
          return this.$message.error(this.$t('customerConfig.entity_has'))
        }
      } catch (err) {
        res = 1
        this.btnloading = false
        this.$httpNotify(err)
      } finally {
        // eslint-disable-next-line no-unsafe-finally
        return res
      }
    },
    async addBasicInfo() {
      try {
        const repeatTaxId = await this.checkSupByTaxIdNumber()
        const repeatSupName = await this.checkBySupName()
        if (repeatTaxId === false && repeatSupName === false) {
          console.log('没有重复的!')
          //  return
          // eslint-disable-next-line no-unreachable
          const params = this.getParams()
          this.btnloading = true
          const data = await this.$http('addUpdateSupEntity', params)
          this.btnloading = false
          this.$message.success(this.$t('customerConfig.add_succ'))
          this.isAddedOne = true
          this.baseInfo = data
          this.$emit('next', 'assign', data)
          console.log(`data`, data)
        } else {
          console.warn('有重复的税号或者实体名！')
        }
      } catch (err) {
        this.btnloading = false
        this.$httpNotify(err)
      }
    },
    async updateBasicInfo() {
      try {
        const noChange1 = this.baseInfo.taxIdNumber === this.form.supplierTax
        const noChange2 = this.baseInfo.name === this.form.supplierName
        const noChange = noChange1 && noChange2

        let repeat1 = null
        let repeat2 = null

        if (!noChange1) {
          repeat1 = !!(await this.checkSupByTaxIdNumber())
        }
        if (!noChange2) {
          repeat2 = !!(await this.checkBySupName())
        }

        if (noChange || (!repeat1 && !repeat2)) {
          this.btnloading = true
          const f = this.form
          const params = {
            id: this.baseInfo.id,
            address: f.supplierAddress,
            cusEntityId: this.cusEntityId,
            name: f.supplierName,
            taxIdNumber: f.supplierTax,
            topManagerEmail: f.supplierAdminEmail,
            topManagerName: f.supplierAdminName,
            produceSystem: f.produceSystem,
            languagePairList: [...this.langPairData].filter((item) => {
              if (item.sourceCode && item.targetCode) {
                delete item.id
                return item
              }
            }),
            productLineIdList: f.contactProductLine || [],
          }
          const data = await this.$http('updateSupEntity', params)
          this.btnloading = false
          this.$emit('next', 'assign', data)
          this.baseInfo = data
        }
      } catch (error) {
        this.btnloading = false
        this.$httpNotify(error)
      }
    },
    getParams() {
      const f = this.form
      const params = {
        address: f.supplierAddress,
        cusEntityId: this.cusEntityId,
        name: f.supplierName,
        taxIdNumber: f.supplierTax,
        topManagerEmail: f.supplierAdminEmail,
        topManagerName: f.supplierAdminName,
        produceSystem: f.produceSystem,
        languagePairList: [...this.langPairData].filter((item) => {
          if (item.sourceCode && item.targetCode) {
            delete item.id
            return item
          }
        }),
        productLineIdList: f.contactProductLine || [],
      }
      console.log(`params`, params)
      return params
    },
    addLangPairSel: debounce(function () {
      this.langPairData.push({
        id: this.langPairData[this.langPairData.length - 1].id + 1,
        sourceCode: undefined,
        targetCode: undefined,
      })
    }, 100),
    delLangPairSel: debounce(function (id) {
      this.$g.del(this.langPairData, id)
    }, 100),
    onRadioChange() {
      // const { value: val } = e.target
      // console.log(`val`, val)
    },
  },
}
</script>

<style lang="less" scoped>
.AddNewSupplierOne {
  .tip-icon {
    position: absolute;
    top: 0px;
    bottom: 0px;
    right: -29px;
    margin: auto;
  }
  .tip-icon1 {
    position: absolute;
    right: -29px;
    top: 10px;
  }
  .tip-icon2 {
    position: absolute;
    right: -29px;
    top: 58px;
  }
  ::v-deep {
    .ant-form-item-label {
      width: 102px;
    }
    .supplier-admin {
      width: calc(102px + 45.83333333%);
      height: 40px;
      // border: 1px solid#000;
      &-name,
      &-email {
        display: inline-block;
      }
      &-name {
        float: left;
        margin-right: 1%;
        width: 51.5%;
        .ant-form-item-control-wrapper {
          width: calc(100% - 102px);
        }
      }
      &-email {
        float: right;
        width: 46%;
        .ant-form-item-control-wrapper {
          width: 100%;
        }
      }
    }
  }
  .lang_pair {
    display: flex;
    line-height: 32px;
    .ant-form-item {
      width: calc(calc(100% - 29px) / 2);
    }
    .rignt-arrow {
      width: 29px;
      height: 32px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-family: PingFangSC-Regular, PingFang SC;
      font-size: 18px;
      color: #46546c;
      line-height: 25px;
      padding: 0 10px;
      margin-top: 4px;
    }
    .select-w {
      // width: 169px;
      width: 100%;
    }
  }
}
</style>
